import loginBox from "@/components/login-box/login-box.vue";
import signBox from "@/components/sign-box/sign-box.vue";
import purseBox from "@/components/purse-box/purse-box.vue";
import setUser from "@/components/set-user/set-user.vue";
import webApi from "@/api/web.api";
import webmsgApi from "@/api/webmsg.api";
import { mapState, mapGetters, mapMutations } from "vuex";
import configService from "@/utils/service";

let socket = null;
let lockReconnect = false;
let msgWsUrl = configService.msgWsUrl;
export default {
  components: { loginBox, signBox, setUser, purseBox },
  props: {},
  data() {
    return {
      activeIndex: "1",
      locale: "mo",
      activeUrl: "/index",
      screenWidth: 0,
      userInfo: null,
      msgNumber: 0,
      titleObj: {
        sign: "註冊",
        forget: "忘記密碼",
        login: "登錄",
        set: "基本信息",
        purse: "選擇錢包",
      },
      heartCheck: {
        timeout: 30000, // 心跳检测时长
        timeoutObj: null, //发送定时器
        serverTimeoutObj: null, //重连定时器
        reset: function () {
          // 重置定时
          clearTimeout(this.timeoutObj);
          clearTimeout(this.serverTimeoutObj);
          return this;
        },
        start: function () {
          // 开启定时
          var self = this;
          this.timeoutObj = setTimeout(function () {
            socket.send(
              JSON.stringify({
                event: "heartCheck",
              })
            );
            self.serverTimeoutObj = setTimeout(function () {
              //心跳时间内收不到消息，主动触发连接关闭，开始重连
              socket.close();
            }, self.timeout);
          }, this.timeout);
        },
      },
      tip: "",
      showTip: false,
      showTipList: [
        "/stamp",
        "/myStamp",
        "/stampDetail",
        "/collect",
        "/tackGoods",
        "/taskType",
        "/orderDetail",
        "/order",
        "/receive",
        "/buy",
        "/cart",
        "/way",
        "/customDetail",
      ],
    };
  },
  computed: {
    localeTitle() {
      var title = "";
      this.$t("localeList").forEach((item) => {
        if (this.locale == item.key) {
          title = item.name;
        }
      });
      return title;
    },
    ...mapGetters({
      walletAddress: "address",
      walletConnect: "connect",
      showDiaLog: "showDiaLog",
      dialogKey: "dialogKey",
      admin: "admin",
      head: "head",
    }),
    getWall() {
      if (this.walletAddress) {
        if (this.walletAddress.length <= 10) {
          return this.walletAddress;
        } else {
          const front = this.walletAddress.slice(0, 6);
          const behind = this.walletAddress.slice(this.walletAddress.length - 4);
          return front + "..." + behind;
        }
      }
    },
  },

  mounted() {
    // this.$store.dispatch(
    //   "connectSuccess",
    //   "0x43422960c77f514eb1f255c63cf15a4cb6d01abc"
    // ); //测试用，打包记得注释·
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;

    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    if (window.ethereum) {
      let that = this;
      window.ethereum.on("accountsChanged", function (data) {
        if (that.$store.getters.connect) {
          // let web3 = await new Web3(window.ethereum);
          // let address = (await web3.eth.getAccounts())[0]
          if (data.length > 0) {
            let address = data[0];
            that.$store.dispatch("changeAddress", address);
          } else {
            that.$store.dispatch("breakConnect");
          }
        }
      });
      window.ethereum.on("disconnect", function (data) {
        that.$store.dispatch("breakConnect");
      });
    }
    if (localStorage.getItem("userInfo")) {
      const data = localStorage.getItem("userInfo");
      this.userInfo = JSON.parse(data);
    }
    this.activeUrl = this.$route.path;
    this.locale = localStorage.locale || "mo";
    this.$i18n.locale = this.locale;
    this.getTip();
    this.ifShowTip();
  },
  //注销window.onresize事件

  destroyed() {
    window.onresize = null;
  },
  beforeDestroy() {
    this.closeSocket();
    socket = null;
  },
  methods: {
    ...mapMutations(["clearAdmin"]),
    // 断开钱包连接
    disconnect() {
      this.$store.dispatch("breakConnect");
    },
    // 点击切换语言
    handleCommand(value) {
      this.$i18n.locale = value;
      this.locale = value;
      localStorage.locale = value;
    },
    showMenu() {
      this.$emit("showMenu");
    },

    // 点击切换登录注册
    diaLogOpen(title) {
      this.$store.commit("openDialog", { show: true, dialogKey: title });
    },
    // 关闭用户设置弹窗
    closeDia() {
      this.$store.commit("closeDialog", { show: false });
    },
    toPage(url) {
      // if(url!='/index'&&!localStorage.PCPowerToken){
      //   this.diaLogOpen('login')
      //   return
      // }
      if (this.activeUrl != url) {
        this.$router.push(url);
      }
    },
    loginOut() {
      this.$confirm("是否確認退出登錄?", "退出登錄", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          webApi.logout().then((res) => {});
          if (this.activeUrl != "/index") {
            this.$router.replace("/index");
          }
          delete localStorage.userInfo;
          delete localStorage.PCPowerToken;
          this.clearAdmin();

          // this.userInfo = null
          this.$message.success("賬號已退出");
          this.closeSocket();
        })
        .catch(() => {});
    },
  
    initSocket() {
      console.log("初始化");
      var that = this;

      socket = new WebSocket(
        msgWsUrl + "/ws?token=" + localStorage.PCPowerToken || ""
      );
      socket.onopen = function () {
        console.log("onopen2");
        that.heartCheck.reset().start();
      };
      socket.onmessage = function (res) {
        console.log("onmessage2");
        that.heartCheck.reset().start();
        if (res.data) {
          var data = JSON.parse(res.data);
          console.log("websoket信息", data);
          if (data.event && data.event == "newMessage") {
            that.getNewMsgNumber();
          }
        }
      };
      socket.onclose = (res) => {
        console.log("onclose2");
        if (lockReconnect) return;
        lockReconnect = true;
        setTimeout(() => {
          socket = null;
          if (localStorage.getItem("token")) {
            this.initSocket();
          }
          lockReconnect = false;
        }, 2000);
      };
      socket.onerror = function (res) {
        console.log("err2");
      };
    },
    closeSocket() {
      lockReconnect = true;
      if (socket) {
        socket.close();
      }
    },
    getNewMsgNumber() {
      webmsgApi.getNewMsgNumber().then((res) => {
        if (res && res.code == 0) {
          this.msgNumber = res.resData;
        }
      });
    },
    getTip() {
      webmsgApi.getPrompt().then((res) => {
        if (res && res.code == 0) {
          var data = res.resData;
          if (data) {
            this.tip = JSON.parse(data).value;
          }
        }
      });
    },
    ifShowTip() {
      this.showTip = this.showTipList.indexOf(this.activeUrl) != -1;
    },
  },

  watch: {
    $route(to, from) {
      this.activeUrl = this.$route.path;
      this.ifShowTip();
      document.querySelector("#pageMenu").scrollIntoView();

    },
    admin(newValue, oldValue) {
      if (newValue) {
        this.initSocket();
        this.getNewMsgNumber();
      }
    },
  },
};
