// 封装請求参数
import http from "./http";

function request({
  method = "get",
  url = "",
  data = {},
  params = {},
  headers = {},
}) {
  return http({
    method,
    url,
    data,
    params,
    headers,
  });
}

export default request;
