import configService from "@/utils/service";
import imageCut from '@/components/image-cut/image-cut.vue'
import webuserApi from "@/api/webuser.api";
import { mapMutations } from "vuex";

var baseURL = configService.baseUrl;
export default {
  components: {
    imageCut
  },
  data() {
    return {
      apiHeader: baseURL,
      fileList: [],
      headers: {
        token: localStorage.PCPowerToken || "",
      },
      formptdata: {
        logo: '',
        name: ''
      },
      showDiaLog: false,
      imgUrl: "",
      screenWidth:1920
    };
  },
  computed: {},
  mounted() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.imgUrl = userInfo.head||''
    this.screenWidth = document.body.clientWidth;
  
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      
      })();
    };
  },

  destroyed() { },
  methods: {
    ...mapMutations(['saveAdmin']),
    closeModel() {
      
      this.$emit('close')
    },
    closeImg(url){
      this.imgUrl = url
      this.showDiaLog = false
    },
    showHead() {
      this.showDiaLog = true
    },
    submit() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
     
      const data = {
        head: this.imgUrl,
        nickname: this.formptdata.name || userInfo.nickname,
      };
      if(!data.head||data.head==''){
        this.$message.warning('請上传头像')
        return
      }
      if(!data.nickname||data.nickname==''){
        this.$message.warning('請輸入昵稱')
        return
      }
      webuserApi.updateInfo(data).then((res) => {
        if (res && res.code == 0) {
          this.saveAdmin(res.resData)
          localStorage.userInfo = JSON.stringify(res.resData);
          localStorage.PCPowerToken = res.resData.loginToken;
          this.$emit('close')
        }
      });

    },
  },
};
