import request from "@/utils/request";

export default {
  // 获取热门项目
  getPopularProject() {
    return request({
      url: "/web/getPopularProject",
      method: "get",
    });
  },
  // 获取热门项目所有套系
  getPopularProjectGallery(id) {
    return request({
      url: "/web/getPopularProjectGallery/" + id,
      method: "get",
    });
  },
  // 获取所有套系
  getGallery(params) {
    return request({
      url: "/web/getGallery",
      method: "get",
      params,
    });
  },
  // 获取套系詳情
  getGalleryDetail(id) {
    return request({
      url: "/web/getGalleryDetail/" + id,
      method: "get",
    });
  },
  //获取套系數字郵票
  getGalleryNft(params) {
    return request({
      url: "/web/getGalleryNft",
      method: "get",
      params,
    });
  },
  // 发送邮箱验证码
  sendCode(params) {
    return request({
      url: "/web/sendCode",
      method: "get",
      params,
    });
  },
  //注册用户
  register(data) {
    return request({
      url: "/web/register",
      method: "post",
      data,
    });
  },
  //忘记密码
  forget(data) {
    return request({
      url: "/web/forget",
      method: "post",
      data,
    });
  },
  //用户登录
  login(data) {
    return request({
      url: "/web/login",
      method: "post",
      data,
    });
  },
  //用户登出
  logout() {
    return request({
      url: "/web/logout",
      method: "post",
    });
  },
  // 忘记密码发送邮箱验证码
  sendForgetCode(params) {
    return request({
      url: "/web/sendForgetCode",
      method: "get",
      params,
    });
  },
  // 修改邮箱发送邮箱验证码
  sendUpdateCode(params) {
    return request({
      url: "/web-user/sendUpdateCode",
      method: "get",
      params,
    });
  },
  // 获取筛选项
  getFilterItem(id) {
    return request({
      url: "/web/getFilterItem/" + id,
      method: "get",
    });
  },
  // 订阅邮件
  subscribe(params) {
    return request({
      url: "/web/subscribe",
      method: "post",
      params,
    });
  },
  // 获取门店列
  getStoreList() {
    return request({
      url: "/web/getStoreList",
      method: "get",
    });
  },
  //预约变更
  updateReservation(data) {
    return request({
      url: "/web-order/updateReservation",
      method: "post",
      data,
    });
  },
  //加入購物車
  insertCart(data) {
    return request({
      url: "/web-shopping-cart/insertOne",
      method: "post",
      data,
    });
  },
  //購物車列表
  getCartList() {
    return request({
      url: "/web-shopping-cart/getList",
      method: "get",
    });
  },
  //刪除購物車
  deleteCart(idList) {
    return request({
      url: "/web-shopping-cart/deleteBatch?idList=" + idList,
      method: "post",
    });
  },
  //購物車修改數量
  cartChangeNum(data) {
    return request({
      url: "/web-shopping-cart/changeNumber",
      method: "post",
      data,
    });
  },
  //确认商品
  confirmGoods(ids) {
    return request({
      url: "/web-shopping-cart/confirmGoods?idList=" + ids,
      method: "get",
    });
  },
};
