// 封装請求
import axios from "axios";
import { Message } from "element-ui";
import configService from "@/utils/service";
import webApi from "@/api/web.api";
var baseURL = configService.baseUrl;
import store from "@/store";

// 請求
const http = axios.create({
  baseURL,
  timeout: 300000,
});
// 請求拦截
http.interceptors.request.use(
  (config) => {
    //請求头设置
    let token = localStorage.PCPowerToken || "";
    if (config.headers) {
      config.headers.token = token;
    } else {
      config.headers = {
        token: token,
      };
    }
    return config;
  },
  () => {}
);
// 响应拦截
http.interceptors.response.use(
  (arr) => {
    let that = this;

    // 对响应码的处理
    console.log(111,arr)

    switch (arr.status) {
      case 200:
        if (arr.data.code != 0) {
          Message({
            message: arr.data.msg,
            type: "warning",
          });
        }
        break;
      case 201:
        Message({
          message: arr.data.msg,
          type: "success",
        });
        break;
      case 204:
        Message({
          message: arr.data.msg,
          type: "success",
        });
        break;
      case 400:
        Message({
          message: arr.data.msg,
          type: "warning",
        });
        break;
      case 401:
        Message({
          message: "登录过期，請重新登录",
          type: "warning",
        });
        webApi.logout().then((res) => {
          if (res && res.code == 0) {
          }
        });
        delete localStorage.userInfo;
        delete localStorage.PCPowerToken;
        store.commit("clearAdmin");
        store.commit("openDialog", { show: true, dialogKey: "login" });
        break;
      case 403:
        Message({
          message: arr.data.msg,
          type: "warning",
        });
        break;
      case 404:
        Message({
          message: arr.data.msg,
          type: "warning",
        });
        break;
      case 422:
        Message({
          message: arr.data.msg,
          type: "warning",
        });
        break;
      case 500:
        Message({
          message: arr.data.msg,
          type: "error",
        });
        break;
      default:
        Message({
          message: arr.data.msg,
          type: "error",
        });
        break;
    }
    return arr.data;
  },
  (err) => {
    let response = err.response;
    
    if (!response) {
      Message({
        message: "請求失敗",
        type: "error",
      });
      return {
        data: "",
        error: "請求失敗",
      };
    }
    const status = response.status;
    console.log(222,err,status);
    const token = localStorage.getItem("PCPowerToken");
    if (status == 401 && token) {
      Message({
        message: "登录过期，請重新登录",
        type: "warning",
      });
      webApi.logout().then((res) => {
        if (res && res.code == 0) {
        }
      });
      delete localStorage.userInfo;
      delete localStorage.PCPowerToken;
      location.reload();
      store.commit("clearAdmin");
      store.commit("openDialog", { show: true, dialogKey: "login" });
    } else if (status == 401) {
      Message({
        message: "請重新登录",
        type: "warning",
      });
      store.commit("clearAdmin");
      store.commit("openDialog", { show: true, dialogKey: "login" });

    } else {
      Message({
        message: response.data.msg || status,
        type: "error",
      });
    }

    return {
      data: "",
      error: status,
    };
  }
);
// 返出
export default http;
