const box = {
    state: {
        showDiaLog: false,
        dialogKey: null,
        admin: null,
        head: null,
        takeList:[]
    },
    mutations: {
        openDialog(state, payload) {
            state.showDiaLog = payload.show
            state.dialogKey = payload.dialogKey
    
        },
        closeDialog(state, payload) {
   
            state.showDiaLog = payload.show
        },
        saveAdmin(state, payload) {
            state.admin = payload.nickname
            state.head = payload.head
        },
        clearAdmin(state) {
            state.admin = null
            state.head = null
        },
        saveTakeList(state, payload) {
            state.takeList = payload
            localStorage.setItem('YPtakeList', JSON.stringify(state.takeList))
           
        },
        clearTakeList(state) {
            state.takeList = []
            localStorage.removeItem('YPtakeList')

        }
    },
    actions: {

    }
}

export default box