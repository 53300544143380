const getters = {
  address: state => state.wallet.address,
  connect: state => state.wallet.connect,
  dialogKey: state => state.box.dialogKey,
  showDiaLog: state => state.box.showDiaLog,
  admin: state => state.box.admin,
  head: state => state.box.head,
  takeList: state => state.box.takeList,
}
export default getters
