<template>
  <div class="img-cut-box">
    <vue-avatar
      :width="screenWidth <= 1300 ? 120 : screenWidth <= 1600 ? 128 : 220"
      :height="screenWidth <= 1300 ? 120 : screenWidth <= 1600 ? 128 : 220"
      :scale="scale"
      :border="screenWidth <= 1300 ? 5 : screenWidth <= 1600 ? 7 : 10"
      :color="[204, 204, 204, 0.5]"
      ref="vueavatar"
      @vue-avatar-editor:image-ready="onImageReady"
      :image="image"
      @select-file="selectFile"
      @imageLoaded="imageLoaded"
    >
    </vue-avatar>

    <br />
    <el-slider v-model="scale" :min="0.5" :step="0.1" :max="3"></el-slider>
    <el-button @click="save" type="primary">保 存</el-button>
  </div>
</template>
<script >
import { VueAvatar } from "vue-avatar-editor-improved";
import webuserApi from "@/api/webuser.api";
export default {
  components: {
    VueAvatar,
  },
  props: {
    image: "",
  },
  data() {
    return {
      scale: 1,
      imgUrl: "",
      uploadStatus: true,
      screenWidth: 1920,
    };
  },
  watch: {},
  created() {
  
  },
  mounted() {
    this.$refs.vueavatar.clicked();
    this.screenWidth = document.body.clientWidth;

    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  beforeDestroy() {},

  destroyed() {},
  methods: {
    imageLoaded(value) {
      this.uploadStatus = value;
    },
    selectFile(value) {

      const type = ["image/png", "image/jpg", "image/jpeg"];
      // 限制大小
      if (value[0].size > 5242880) {
        setTimeout(() => {
          this.$message.warning("图片大小最大为5MB");
          this.$refs.vueavatar.resetImage();
        }, 500);
        return false;
      }
      if (type.indexOf(value[0].type) == -1) {
        setTimeout(() => {
          this.$message.info(`只能上傳${type.join("、")}文件`);
          this.$refs.vueavatar.resetImage();
        }, 500);
        return false;
      }
    },
    saveClicked() {
      var img = this.$refs.vueavatar.getImageScaled();
      this.$refs.image.src = img.toDataURL();
    },
    onImageReady() {
      this.scale = 1;
      this.rotation = 0;
    },
    save() {
      if (this.uploadStatus) {
        var img = this.$refs.vueavatar.getImageScaled();
        const index = img.toDataURL().indexOf(",");
        const data = img.toDataURL().slice(index + 1);

        const reqData = { data: data, suffix: "jpg" };
        webuserApi.toImg(reqData).then((res) => {
          if (res && res.code == 0) {
            this.imgUrl = res.resData;
            this.$emit("closeModel", this.imgUrl);
          }
        });
      } else {
        this.$message.info("請選擇头像");
      }
    },
  },
};
</script>
<style scoped lang="less" >
.img-cut-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  .el-slider {
    width: 50%;
    /deep/ .el-slider__bar {
      background-color: #7162ff;
    }
    /deep/.el-slider__button {
      border-color: #7162ff;
    }
  }
}
@media all and (max-width: 1600px) {
  .img-cut-box {
    margin-bottom: 20px;
    .el-slider {
      width: 70%;
    
    }
  }
}

</style>
    