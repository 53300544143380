import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./theme/index.css";
import "swiper/dist/css/swiper.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "@/assets/css/main.less";
import moment from "moment";
import VueAwesomeSwiper from "vue-awesome-swiper";
import store from "./store";
import i18n from "./i18n";
import lang from "element-ui/lib/locale/lang/zh-TW";
import locale from "element-ui/lib/locale";
import topMenu from "@/components/topMenu/topMenu.vue";


locale.use(lang);
Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();
document.title = "數郵中國";

Vue.use(ElementUI).use(VueAwesomeSwiper);
Vue.component('topMenu',topMenu)
Vue.filter("filterTime", (time) => {
  if (time && time !== "") {
    return moment(time).format("yyyy-MM-DD HH:mm");
  } else {
    return "";
  }
});
Vue.filter("filterTime2", (time) => {
  if (time && time !== "") {
    return moment(time).format("yyyy年MM月DD日HH時mm分");
  } else {
    return "";
  }
});

Vue.directive("animate", {
  inserted: function (el, binding) {
    // 聚焦元素
    binding.addClass = () => {
      const { top } = el.getBoundingClientRect();
      const h =
        document.documentElement.clientHeight || document.body.clientHeight;
      if (top < h) {
        if (el.className.indexOf(binding.value) == -1) {
          // 初次还未绑定过，则新增类名(注意：下面单引号中间是一个空格！！！)
          el.className = binding.value + " " + el.className;
        }
        if (binding.addClass) {
          window.removeEventListener("scroll", binding.addClass);
        }
      }
    };
    window.addEventListener("scroll", binding.addClass, true);
    binding.addClass();
  },
  unbind: function (el, binding) {
    if (binding.addClass) {
      window.removeEventListener("scroll", binding.addClass);
    }
  },
});

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
  beforeCreate() {
    // this就是Vue实例对象vm   ,vm上有$on,$emit,$off，直接用它就行
    Vue.prototype.$bus = this; // 安装全局事件总线
  },
}).$mount("#app");
