import webApi from "@/api/web.api";
import { encrypt, decrypt } from "@/utils/AES.js";
import { mapMutations } from "vuex";
export default {
  components: {},
  props: {
    signTitle: "",
  },
  data() {
    return {
      ruleForm: {
        email: "",
        code: "",
        pass: "",
        pass2: "",
      },
      rules: {
        email: [
          { required: true, message: "請輸入郵箱", trigger: "blur" },
          { validator: this.emailRule, trigger: "blur" },
        ],
        code: [{ required: true, message: "請輸入驗證碼", trigger: "blur" }],
        pass: [
          { required: true, message: "請輸入密碼", trigger: "blur" },
          { validator: this.passRule, trigger: "blur" },
        ],
        pass2: [
          { required: true, message: "請確認密碼", trigger: "blur" },
          { validator: this.subPass, trigger: "blur" },
        ],
      },
      checked: true,
      ifGetCode: false,
      time: 60,
      timer: null,
      sysData: {},
    };
  },
  computed: {},
  mounted() {
    this.sysData = JSON.parse(localStorage.getItem("KMsysData")) || {};
  },
  //注销window.onresize事件
  destroyed() {},
  methods: {
    ...mapMutations(["saveAdmin"]),
    //验证密码是否一致
    subPass(rule, value, callback) {
      if (value != this.ruleForm.pass) {
        callback("两次密码不一致");
      } else {
        callback();
      }
    },
    // 验证密码格式
    passRule(rule, value, callback) {
      const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      if (!reg.test(value)) {
        callback("密碼長度6-16位，需同時包含字母、數字，不得包含其他符號");
      } else {
        callback();
      }
    },
    //郵箱验证
    emailRule(rule, value, callback) {
      const reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!reg.test(value)) {
        callback("請輸入正确的郵箱格式");
      } else {
        callback();
      }
    },
    changeDia(title) {
      this.$emit("changeDia", title);
    },
    // 获取验证码
    getCode() {
      if (this.ruleForm.email) {
        if (!this.ifGetCode) {
          if (this.signTitle != "" && this.signTitle == "forget") {
            webApi
              .sendForgetCode({ email: this.ruleForm.email })
              .then((res) => {
                if (res.code == 0) {
                  this.time = 60;
                  this.ifGetCode = true;
                  this.timer = setInterval(() => {
                    if (this.time == 0) {
                      this.ifGetCode = false;
                      clearInterval(this.timer);
                      this.timer = null;
                    } else {
                      this.time--;
                    }
                  }, 1000);
                }
              });
          } else {
            webApi.sendCode({ email: this.ruleForm.email }).then((res) => {
              if (res.code == 0) {
                this.time = 60;
                this.ifGetCode = true;
                this.timer = setInterval(() => {
                  if (this.time == 0) {
                    this.ifGetCode = false;
                    clearInterval(this.timer);
                    this.timer = null;
                  } else {
                    this.time--;
                  }
                }, 1000);
              }
            });
          }
        }
      } else {
        this.$message.warning("請先輸入郵箱");
      }
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            email: this.ruleForm.email,
            password: this.ruleForm.pass,
            isSubscribe: null,
            code: this.ruleForm.code,
          };
          data.password = encrypt(
            data.password,
            this.sysData.aesKey,
            this.sysData.aesIv
          );
          this.checked ? (data.isSubscribe = 1) : (data.isSubscribe = 0);
          // 判断是忘记密码（forget）还是注册用户（sign）
          if (this.signTitle != "" && this.signTitle == "forget") {
            //忘记密码
            delete data.isSubscribe; //清除不需要的属性

            webApi.forget(data).then((res) => {
              if (res && res.code == 0) {
                this.$message.success("修改成功");
                this.$refs[formName].resetFields();
              }
            });
          } else {
            //注册用户

            webApi.register(data).then((res) => {
              if (res && res.code == 0) {
                //注册成功后设置头像
                const data = res.resData;
                this.saveAdmin(data);
                localStorage.PCPowerToken = data.loginToken;
                localStorage.userInfo = JSON.stringify(data);
                this.$message.success("注册成功");
                this.changeDia("set");
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
