import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import { Message } from "element-ui";
import store from "@/store";
import configService from "@/utils/service";

const routes = [
  {
    path: "*",
    redirect: "/index",
  },
  {
    path: "/system",
    name: "system",
    component: () => import("@/views/system-manage/system-manage.vue"),
    meta: {
      title: "系统页",
    },
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("@/views/system-manage/index/index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/nft",
        name: "nft",
        component: () => import("@/views/system-manage/nft/nft.vue"),
        meta: {
          title: "nft",
        },
      },
      {
        path: "/collect",
        name: "collect",
        component: () => import("@/views/system-manage/collect/collect.vue"),
        meta: {
          title: "我的收藏",
        },
      },
      {
        path: "/tackGoods",
        name: "tackGoods",
        component: () => import("@/views/system-manage/collect/collect.vue"),
        meta: {
          title: "我的收藏",
        },
      },
      {
        path: "/taskType",
        name: "task-type",
        component: () =>
          import("@/views/system-manage/collect/task-type/task-type.vue"),
        meta: {
          title: "提貨",
        },
      },
      {
        path: "/taskSuccess",
        name: "taskSuccess",
        component: () =>
          import("@/views/system-manage/collect/taskSuccess/taskSuccess.vue"),
        meta: {
          title: "提貨成功",
        },
      },
      {
        path: "/gallery",
        name: "gallery",
        component: () => import("@/views/system-manage/gallery/gallery.vue"),
        meta: {
          title: "画廊",
        },
      },
      {
        path: "/stamp",
        name: "stamp",
        component: () => import("@/views/system-manage/stamp/stamp.vue"),
        meta: {
          title: "購買郵票",
        },
      },
      {
        path: "/myStamp",
        name: "myStamp",
        component: () => import("@/views/system-manage/myStamp/myStamp.vue"),
        meta: {
          title: "我的數字郵票",
        },
      },
      {
        path: "/stampDetail",
        name: "stamp-detail",
        component: () =>
          import("@/views/system-manage/stamp/stamp-detail/stamp-detail.vue"),
        meta: {
          title: "商品詳情",
        },
      },
      {
        path: "/clause",
        name: "clause",
        component: () => import("@/views/system-manage/clause/clause.vue"),
        meta: {
          title: "隱私政策",
        },
      },
      {
        path: "/agreement",
        name: "agreement",
        component: () =>
          import("@/views/system-manage/agreement/agreement.vue"),
        meta: {
          title: "使用條款",
        },
      },
      {
        path: "/message",
        name: "message",
        component: () => import("@/views/system-manage/message/message.vue"),
        meta: {
          title: "消息",
          keepAlive: true,
        },
      },
      {
        path: "/messageDetail",
        name: "messageDetail",
        component: () =>
          import(
            "@/views/system-manage/message/message-detail/message-detail.vue"
          ),
        meta: {
          title: "消息詳情",
         
        },
      },
      {
        path: "/order",
        name: "order",
        component: () => import("@/views/system-manage/order/order.vue"),
        meta: {
          title: "订单管理",
          keepAlive: true,

        },
        beforeEnter: (to, from, next) => {
          console.log("beforeEnter");
          next();
        },
      },
      {
        path: "/receive",
        name: "receive",
        component: () => import("@/views/system-manage/receive/receive.vue"),
        meta: {
          title: "提貨管理",
          keepAlive: true,

        },
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/views/system-manage/user/user.vue"),
        meta: {
          title: "账号管理",
        },
      },
      {
        path: "/orderDetail",
        name: "order-detail",
        component: () =>
          import("@/views/system-manage/order/detail/detail.vue"),
        meta: {
          title: "订单詳情",
        },
      },
      {
        path: "/customDetail",
        name: "custom-detail",
        component: () =>
          import("@/views/system-manage/order/custom-detail/custom-detail.vue"),
        meta: {
          title: "订单詳情",
        },
      },
      {
        path: "/galleryChildren",
        name: "gallery-children",
        component: () =>
          import("@/views/system-manage/gallery/children/children.vue"),
        meta: {
          title: "画廊合集",
        },
      },
      {
        path: "/buy",
        name: "gallery-buy",
        component: () => import("@/views/system-manage/gallery/buy/buy.vue"),
        meta: {
          title: "购买郵票",
        },
      },
      {
        path: "/buyDetail",
        name: "gallery-buyDetail",
        component: () =>
          import("@/views/system-manage/gallery/buyDetail/buyDetail.vue"),
        meta: {
          title: "支付",
        },
      },
      {
        path: "/payLoad",
        name: "gallery-payLoad",
        component: () =>
          import("@/views/system-manage/gallery/buyDetail/payLoad.vue"),
        meta: {
          title: "支付中",
        },
      },
      {
        path: "/buySuccess",
        name: "gallery-buySuccess",
        component: () =>
          import(
            "@/views/system-manage/gallery/buyDetail/buySuccess/buySuccess.vue"
          ),
        meta: {
          title: "支付成功",
        },
      },
      {
        path: "/lineMap",
        name: "lineMap",
        component: () => import("@/views/system-manage/line-map/line-map.vue"),
        meta: {
          title: "路線圖&白皮书",
        },
      },
      {
        path: "/instructions",
        name: "instructions",
        component: () =>
          import("@/views/system-manage/instructions/instructions.vue"),
        meta: {
          title: "使用說明",
        },
      },
      {
        path: "/cart",
        name: "cart",
        component: () => import("@/views/system-manage/cart/cart.vue"),
        meta: {
          title: "購物車",
        },
      },
      {
        path: "/way",
        name: "way",
        component: () => import("@/views/system-manage/cart/way/way.vue"),
        meta: {
          title: "購買方式",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  const result =originalPush.call(this, location);
  return result.catch((err) => err)
}


const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  const result = originalReplace.call(this, location);
  return result.catch((err) => err);
};
const noTokenurl = [
  "/index",
  "/lineMap",
  "/clause",
  "/instructions",
  "/agreement",
  "/nft",
  "/gallery",
  "/galleryChildren",
  "/collect",
  "/stamp",
];

// 路由守衛
router.beforeEach((to, from, next) => {
  var screenWidth = document.body.clientWidth;
  if (screenWidth < 768) {
    window.location.href = configService.homeUrl;
  } else {
    if (noTokenurl.indexOf(to.path) > -1 ) {


      next();
    } else if (!localStorage.PCPowerToken) {
      store.commit("openDialog", { show: true, dialogKey: "login" });
      Message({
        message: "請先登錄",
        type: "warning",
      });
      
      if(noTokenurl.indexOf(from.path) ==-1){
        router.push({ path: "/index" });
      }
      // next();
    } else {
    

      next();
    }
  }
});

export default router;
