import Web3 from "web3";
export default {
  components: {},
  data() {
    return {
      web3: null,
    };
  },
  computed: {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    async connectMetaMask() {
      if (!window.ethereum) {
        if(confirm("未安裝MetaMask，是否跳轉到MetaMask官網")){
          location.href = "https://metamask.io/"
        }
        return;
      }
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const address = accounts[0];
        this.$store.dispatch('connectSuccess', address)
        this.$emit('close')
        this.$message.success({
            message: '連接成功',
            duration: 1000
        })
      } catch (error) {
        this.$store.dispatch('breakConnect')
        console.error(error);
      }
    },
    async connectOKX() {
      if (typeof window.okxwallet !== 'undefined') {
        try {
          const accounts = await okxwallet.request({ method: 'eth_requestAccounts' });
          const address = accounts[0]
          this.$store.dispatch('connectSuccess', address)
          this.$emit('close')
          this.$message.success({
            message: '連接成功',
            duration: 1000
          })
        } catch (error) {
          this.$store.dispatch('breakConnect')
          console.error(error);
        }
      }else{
        if(confirm("未安裝歐易錢包，是否前往安裝")){
          location.href = "https://chromewebstore.google.com/detail/%E6%AC%A7%E6%98%93-web3-%E9%92%B1%E5%8C%85/mcohilncbfahbmgdjkbpemcciiolgcge"
        }
        return;
      }
    }
  },
};
