const configService = {
  baseUrl: 'https://stamp.dgsfintech.com/ms-base', //生产环境
  wsUrl: 'wss://stamp.dgsfintech.com/wsUrl',//生产环境
  msgWsUrl: 'wss://stamp.dgsfintech.com/msgWsUrl',//生产环境
  homeUrl: "https://stamp.dgsfintech.com/h5", //移动端地址

  // baseUrl: 'https://test.dgsfintech.com/ms-base', //生产环境
  // wsUrl: 'wss://test.dgsfintech.com/wsUrl',//生产环境
  // msgWsUrl: 'wss://test.dgsfintech.com/msgWsUrl',//生产环境
  // homeUrl: "https://test.dgsfintech.com/h5", //移动端地址
  // baseUrl: "http://192.168.50.103:8082/ms-base", //嘉文
  // wsUrl: "ws://192.168.50.204:18092",
  // msgWsUrl: "ws://192.168.50.204:18093",
  // baseUrl: "http://192.168.50.23:8082/ms-base", //正义哥
  // wsUrl: "ws://192.168.50.23:18092",
  // msgWsUrl: "ws://192.168.50.23:18093",
  // baseUrl: 'http://ec2-18-163-198-19.ap-east-1.compute.amazonaws.com/ms-base', //生产环境
  // wsUrl: 'ws://ec2-18-163-198-19.ap-east-1.comput.compute.amazonaws.com:18092',//测试环境
  // msgWsUrl: 'ws://ec2-18-163-198-19.ap-east-1.comput.compute.amazonaws.com:18093',//测试环境
  // homeUrl:'https://ec2-18-163-198-19.ap-east-1.comput.compute.amazonaws.com/h5', //移动端地址
  fileUrl: "https://stamp-mail.oss-cn-hongkong.aliyuncs.com",
};
export default configService;
