import Vue from 'vue'
import Vuex from 'vuex'
import wallet from './modules/wallet'
import box from './modules/box'

import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        wallet,
        box
    },
    getters
})

export default store
