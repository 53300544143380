import Cookies from 'js-cookie'
import Web3 from "web3";

function getToken(key) {
    return Cookies.get(key)
}

function setToken(key, value) {
    return Cookies.set(key, value)
}

function removeToken(key) {
    return Cookies.remove(key)
}
async function initAddress() {
    let result = null;
    try {
        if (window.ethereum) {
            let status = getToken("wallectConnect")
            if (status) {
                // let web3 = await new Web3(window.ethereum);
                // result = (await web3.eth.getAccounts())[0]
                // result = window.ethereum.selectedAddress
                const accounts = await window.ethereum.request({method: 'eth_accounts'})
                if(accounts.length > 0){
                    result = accounts[0]
                }else{
                    removeToken("walletAddress")
                }
            }
        }
    } catch (error) {
        console.error(error);
    }
    return result;
}
async function initConnect(){
    let result = false
    try {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({method: 'eth_accounts'})
            if(accounts.length > 0){
                result = getToken("wallectConnect")
            }else{
                removeToken("wallectConnect")
            }
        }
    } catch (error) {
        console.error(error);
    }
    return result
}
const wallet = {
    state: {
        address: await initAddress(),
        connect: await initConnect()
    },
    mutations: {
        SET_ADDRESS: (state, address) => {
            state.address = address
        },
        SET_CONNECT: (state, connect) => {
            state.connect = connect
        },
    },
    actions: {
        connectSuccess({ commit }, address){
            setToken("walletAddress", address)
            setToken("wallectConnect", true)
            commit('SET_CONNECT', true)
            commit('SET_ADDRESS', address)
        },
        breakConnect({ commit }){
            removeToken("walletAddress")
            removeToken("wallectConnect")
            commit('SET_CONNECT', false)
            commit('SET_ADDRESS', null)
        },
        changeAddress({ commit }, address){
            setToken("walletAddress", address)
            commit('SET_ADDRESS', address)
        }
    }
}

export default wallet
