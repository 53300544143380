import webApi from "@/api/web.api";
import {
  encrypt,
  decrypt
} from "@/utils/AES.js"
import { mapMutations } from "vuex";
export default {
  components: {},
  data() {
    return {
      ruleForm: {
        email: '',
        pass: '',
      },
      activeUrl: "/index",
      rules: {
        email: [
          { required: true, message: '請輸入郵箱', trigger: 'blur' },
        ],
        pass: [
          { required: true, message: '請輸入密碼', trigger: 'blur' },
        ],
      },
      checked: false,
      sysData: {}
    };
  },
  computed: {
  },
  watch: {
    $route(to, from) {
      this.activeUrl = this.$route.path;
    },
  },
  mounted() {

    this.activeUrl = this.$route.path;
    this.sysData = JSON.parse(localStorage.getItem('KMsysData')) || {}
    if(localStorage.SLLoginInfo){
      this.checked=true
      let userLoginInfo = JSON.parse(localStorage.SLLoginInfo);
      this.ruleForm={
        email:userLoginInfo.username,
        pass: decrypt(userLoginInfo.password, this.sysData.aesKey, this.sysData.aesIv),
      }
    }
    // if (localStorage.KMsysData && this.sysData !== {})
  },

  destroyed() {
  },
  methods: {
    ...mapMutations(['saveAdmin']),
    changeDia(title) {
      this.$emit('changeDia', title)
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.changeDia('set')
          const data1 = {
            username: this.ruleForm.email,
            password: this.ruleForm.pass
          }
          data1.password = encrypt(data1.password, this.sysData.aesKey, this.sysData.aesIv)
          webApi.login(data1).then((res) => {
            if (res && res.code == 0) {
              const data = res.resData
              localStorage.PCPowerToken = data.loginToken;
              localStorage.userInfo = JSON.stringify(data)
              this.saveAdmin(data)

              this.$message.success('登錄成功')
              if(this.checked){
                localStorage.SLLoginInfo = JSON.stringify(data1);
               
              }else{
                localStorage.SLLoginInfo = null;
              }
              this.$emit('close')
             
          
            }
          })
        } else {
  
          return false;
        }
      })
    }
  },
};
