import { render, staticRenderFns } from "./set-user.vue?vue&type=template&id=2722f5e7&scoped=true"
import script from "./set-user.js?vue&type=script&lang=js&external"
export * from "./set-user.js?vue&type=script&lang=js&external"
import style0 from "./set-user.less?vue&type=style&index=0&id=2722f5e7&prod&scoped=true&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2722f5e7",
  null
  
)

export default component.exports