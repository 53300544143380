import request from "@/utils/request";

export default {
  // 查询消息
  getList(params) {
    return request({
      url: "/web-message/getList",
      method: "get",
      params,
    });
  },
  // 获取活动消息詳情信息
  getActiveDetail(params) {
    return request({
      url: "/web-message/getActiveDetail",
      method: "get",
      params,
    });
  },
  // 获取公告
  getNoticeList() {
    return request({
      url: "/web/getNoticeList",
      method: "get",
     
    });
  },
  // 已读信息
  readBatch(ids) {
    return request({
      url: "/web-message/readBatch?idList=" + ids,
      method: "post",
    });
  },
  // 查询新消息数量
  getNewMsgNumber() {
    return request({
      url: "/web-message/getNewMsgNumber",
      method: "get",
    });
  },
  //查询提示语
  getPrompt() {
    return request({
      url: "/common/getPrompt",
      method: "get",
    });
  },
};
