<template>
  <div id="app">
    <topMenu id="pageMenu"></topMenu>
      <keep-alive>
        <!-- 需要缓存的视图组件 -->
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <!-- 不需要缓存的视图组件 -->
      <router-view v-if="!$route.meta.keepAlive"></router-view>

  </div>
</template>
<script>
import webuserApi from "./api/webuser.api";

export default {
  created() {
   
    webuserApi.getConfig().then((res) => {
      if (res && res.resData) {
        var data = res.resData || {};
        localStorage.setItem("KMsysData", JSON.stringify(data));
      }
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo) {
        this.$store.commit("saveAdmin", userInfo);
      }
    });
    localStorage.getItem("YPtakeList") &&
      this.$store.commit(
        "saveTakeList",
        JSON.parse(localStorage.getItem("YPtakeList"))
      );
      setTimeout(() => {
        this.$bus.$emit('showDiaInfo')
      }, 3000);
  },
};
</script>
<style lang="less"></style>
