
const CryptoJS = require("crypto-js");

//加密，key1：密钥；iv1：偏移量；word：明文数据
export function encrypt( word,key1,iv1) {

    var key = CryptoJS.enc.Utf8.parse(key1);
    var iv = CryptoJS.enc.Utf8.parse(iv1);
    var srcs = CryptoJS.enc.Utf8.parse(word);
    //CBC是工作模式 Pkcs7是填充模式
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}
  
export function decrypt( word,key1,iv1) {
    //解密，key1：密钥；iv1：偏移量；word：密文数据
    //用什么密钥+偏移量加密，就要用什么密钥+偏移量解密
    var key = CryptoJS.enc.Utf8.parse(key1);
    var iv = CryptoJS.enc.Utf8.parse(iv1);
    var decrypt = CryptoJS.AES.decrypt(word, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}