import request from "@/utils/request";

export default {
    // 修改邮箱
    updateEmail(data) {
        return request({
            url: "/web-user/updateEmail",
            method: "post",
            data
        });
    },
    // 修改信息
    updateInfo(data) {
        return request({
            url: "/web-user/updateInfo",
            method: "post",
            data
        });
    },
    //修改密码
    updatePassword(data) {
        return request({
            url: "/web-user/updatePassword",
            method: "post",
            data
        });
    },
    // base转图片
    toImg(data) {
        return request({
            url: "/rest/file/base64ToImg",
            method: "post",
            data
        });
    },
    // 跳过修改信息
    skipUpdateInfo() {
        return request({
            url: "/web-user/skipUpdateInfo",
            method: "post"
        });
    },
    // 查询系统配置
    getConfig() {
        return request({
            url: "/sys-config/getConfig",
            method: "get"
        });
    }
};